import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "joelHayhurst",
  "categories": ["linux", "config"],
  "date": "2021-06-29",
  "path": "/blog/imagemagick-policy-xml",
  "summary": "We configure the resource constraints in ImageMagick's policy.xml",
  "title": "ImageMagick policy.xml constraints",
  "image": "./imagemagick-logo.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "ImageMagick policy.xml constraints",
      "description": "We configure the resource constraints in ImageMagick's policy.xml"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A little while ago I found myself updating a client's version of Ruby. They were currently on version 2.2 and I was bringing them up to 2.4. However, we discovered an unexpected problem in CI. One of the RSpec tests that was processing PDF files was stalling out, and the CI job would never complete.`}</p>
    <p>{`I suspected this might have something to do with resource issues, but didn't know what would be causing them. I compared the versions of Debian provided by the Ruby 2.2 Docker image I was using with the 2.4 version, and found that the 2.2 version was running Debian 8 while the 2.4 version was running Debian 10. This made me suspect that the problem might be related to the change in Debian version.`}</p>
    <p>{`I did some googling around and came to the conclusion it was caused by a change to the ImageMagick configuration. Specifically, a change to the policy.xml file which was released with ImageMagick on Debian 9. Some other people online were reporting similar issues.`}</p>
    <p>{`The Debian team `}<a parentName="p" {...{
        "href": "https://wiki.debian.org/imagemagick/security"
      }}>{`had decided`}</a>{` that some new defaults had to be added to this file for security reasons. ImageMagick by itself does not define these constraints. Because Debian made this change, it went downstream to Ubuntu, and is also present in 18.04.`}</p>
    <p>{`The security concern is that people may upload malicious image files that are designed to overwhelm the resources of the server in processing. These new constraints are designed to prevent unusual levels of memory usage in image processing, amongst other things.`}</p>
    <p>{`However, the constraints were too strict for our intended usage. Therefore, I had to alter the constraints.`}</p>
    <p>{`At first, I tried removing the constraints entirely, on recommendation of someone I found online. This did in fact solve the issue in CI. However, I decided this solution is not ideal, as the constraints do serve a legitimate security purpose.`}</p>
    <p>{`Instead, I changed the values of the constraints to be significantly higher, and this also solved our CI problem.`}</p>
    <p>{`The code I used in our Dockerfile to edit the default policy.xml is the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Increase memory and map constraints in ImageMagick
RUN sed -i 's/name="memory" value="256MiB"/name="memory" value="1GiB"/' /etc/ImageMagick-6/policy.xml
RUN sed -i 's/name="map" value="512MiB"/name="map" value="1GiB"/' /etc/ImageMagick-6/policy.xml`}</code></pre></div>
    <p>{`In another related issue, I had to re-enable GhostScript formats, which were vital to this application. They had been disabled by a new change to policy.xml. The code I used for re-enabling them simply deletes the relevant lines from policy.xml.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`# Re-enable GhostScript formats in ImageMagick
RUN sed -i '/disable ghostscript format types/,+6d' /etc/ImageMagick-6/policy.xml`}</code></pre></div>
    <p>{`I thought this experience might be worth relaying for the benefit of anyone else dealing with similar problems. Thank you for reading.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      